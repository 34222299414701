@charset "utf-8";
/**公共样式**/

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption{border:0 none;vertical-align:baseline;}
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td{font-family:inherit;font-size:100%;font-style:inherit;font-weight:inherit;}
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td {margin:0px;padding:0px;}
body{padding:0px;margin:0px;font-family:'Microsoft YaHei';}
input {outline: none;resize:none;}
textarea {outline: none;resize:none;}
a{text-decoration: none;color: #000000}
.hidden-layer{display:none;}
